table {
  border-collapse: collapse;
  margin: $base-spacing 0;
  text-align: left;
  width: 100%;
}

thead {
  line-height: $heading-line-height;
  vertical-align: bottom;
}

tbody {
  vertical-align: top;
}

tr {
  border-bottom: $base-border;
}

th {
  font-weight: 600;
}

th,
td {
  padding: $small-spacing $base-spacing;
  @media screen and (max-width: 1400px) {
    padding: $small-spacing;
    padding-left: $base-spacing
  }
}
